import { useEffect } from "react"
import { navigate } from "gatsby"

export default () => {
  useEffect(() => {
    navigate("/teleconsults/terms-and-conditions")
  }, [])

  return null
}
